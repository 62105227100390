import React from "react";
import "./Selection.css";

class Selection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      manufacturer: "#",
      curbWeight: 300,
      transmissionType: "#",
      engineDisplacement: 2000,
      retailPrice: 30000
    };
    this.handleCurbWeight = this.handleCurbWeight.bind(this);
    this.handleEngineDisplacement = this.handleEngineDisplacement.bind(this);
    this.handleManufacturer = this.handleManufacturer.bind(this);
    this.handleTransmissionType = this.handleTransmissionType.bind(this);
    this.handleRetailPrice = this.handleRetailPrice.bind(this);
  }
  handleCurbWeight(event) {
    this.setState({curbWeight: event.target.value});
  }
  handleEngineDisplacement(event) {
    this.setState({engineDisplacement: event.target.value});
  }
  handleManufacturer(event) {
    this.setState({manufacturer: event.target.value});
  }
  handleTransmissionType(event) {
    this.setState({transmissionType: event.target.value});
  }
  handleRetailPrice(event) {
    this.setState({retailPrice: event.target.value});
  }
  render() {
    return (
      <React.Fragment>
        <header>
        <a href="/"><h6>Motorhead</h6></a>
        <nav>
          <a href="/">Home</a>
          <a href="/selection">Selection</a>
        </nav>
        </header>
        <div id="selection">
          <section className="filters">
            <article>
              <label htmlFor="manufacturer-select"><strong>Manufacturer</strong></label>
              <select value={this.state.manufacturer} onChange={this.handleManufacturer} id="manufacturer-select">
                <option value="#">All</option>
                <option value="Harley-Davidson">Harley-Davidson</option>
                <option value="Honda">Honda</option>
                <option value="Indian">Indian</option>
                <option value="Kawasaki">Kawasaki</option>
                <option value="Guzzi">Guzzi</option>
                <option value="Suzuki">Suzuki</option>
                <option value="Triumph">Triumph</option>
                <option value="Yamaha">Yamaha</option>
              </select>
            </article>
            <article>
              <label htmlFor="curb-weight-range"><strong>Curb Weight (Up To {this.state.curbWeight} KG) </strong></label>
              <input type="range" value={this.state.curbWeight} id="curb-weight-range" onChange={this.handleCurbWeight} min="130" max="300" step="2"></input>
            </article>
            <article>
              <label htmlFor="transmission-type-select"><strong>Transmission Type</strong></label>
              <select value={this.state.transmissionType} onChange={this.handleTransmissionType} id="transmission-type-select">
                <option value="#">All</option>
                <option value="5-Speed">5-Speed</option>
                <option value="6-Speed">6-Speed</option>
              </select>
            </article>
            <article>
              <label htmlFor="engine-displacement-range"><strong>Engine Displacement (Up To {this.state.engineDisplacement} CC)</strong></label>
              <input type="range" value={this.state.engineDisplacement} id="engine-displacement-range" onChange={this.handleEngineDisplacement} min="0" max="2000" step="20"></input>
            </article>
            <article>
              <label htmlFor="retail-price-range"><strong>Retail Price (Up To {this.state.retailPrice} $)</strong></label>
              <input type="range" value={this.state.retailPrice} id="retail-price-range" onChange={this.handleRetailPrice} min="1000" max="30000" step="300"></input>
            </article>
          </section>
          <section className="images">
          {Object.entries(this.props.motorcycles).map(([key, item]) => {
            if (item.EngineDisplacement <= this.state.engineDisplacement &&
              item.RetailPrice <= this.state.retailPrice &&
              item.CurbWeight <= this.state.curbWeight &&
              (this.state.transmissionType === "#" || item.TransmissionType === this.state.transmissionType) &&
              (this.state.manufacturer === "#" || item.Manufacturer === this.state.manufacturer)) {
              return (
                <React.Fragment key={item.ID}>
                  <a href={"/motorcycle/" + key}>
                    <img src={item.SideviewImagePath}></img>
                    <h1>{item.Name}</h1>
                  </a>
                </React.Fragment>
              );
            }
          })}
          </section>
        </div>
      </React.Fragment>
    );
  }
}
export default Selection;
