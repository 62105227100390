import React from "react";
import { useParams } from "react-router-dom";
import "./Motorcycle.css";

export default function Motorcycle(props) {
  const { key } = useParams();
  if(key in props.motorcycles) {
    let motorcycle = props.motorcycles[key];
    let styles = {
      background: "url(" + motorcycle.HeroImagePath + ") no-repeat center",
    };
    return (
    <React.Fragment>
      <header>
        <a href="/"><h6>Motorhead</h6></a>
        <nav>
          <a href="/">Home</a>
          <a href="/selection">Selection</a>
        </nav>
      </header>
      <main>
        <section class="motorcycle-image" style={styles}></section>
        <p class="name">{motorcycle.Name}</p>
        <img src={motorcycle.SideviewImagePath}></img>
        <section class="information">
          <section class="details">
            <strong>Details:</strong>
            <p>{motorcycle.Details}</p>
          </section>
          <section class="specs">
            <p><strong>Manufacturer:</strong> {motorcycle.Manufacturer}</p>
            <p><strong>Curb Weight:</strong> {motorcycle.CurbWeight} KG</p>
            <p><strong>Engine Type:</strong> {motorcycle.EngineType}</p>
            <p><strong>Engine Displacement:</strong> {motorcycle.EngineDisplacement} CC</p>
            <p><strong>Transmission Type:</strong> {motorcycle.TransmissionType}</p>
            <p><strong>Retail Price:</strong> {motorcycle.RetailPrice} $</p>
          </section>
        </section>
        <iframe src={motorcycle.ReviewLink}></iframe>
      </main>
    </React.Fragment>
    );
  } else {
    return null;
  }
}
