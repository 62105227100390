import React from "react";
import "./Homepage.css";

export default function Homepage() {
  return (
    <body>
      <header>
        <a href="/"><h6>Motorhead</h6></a>
        <nav>
          <a href="/">Home</a>
          <a href="/selection">Selection</a>
        </nav>
      </header>
      <main>
        <section class="hero-image">
          <section>
            <h6>There's a Cruiser in All of Us</h6>
            <p>A selection of the best available cruisers on the market today ranging from beefy Harley-Davidson warhorses to swift Suzuki bobbers</p>
            <a href="/selection">Check It Out</a>
          </section>
        </section>
        <section class="cruiser-lingo">
          <h1 class="title">Cruiser Lingo</h1>
          <section class="container">
            <article>
              <h1>Cruiser</h1>
              <p>Cruisers are heavy, bad-ass motorcycles with low-riding positions suitable for lengthier rides</p>
            </article>
            <article>
              <h1>Custom</h1>
              <p>Customs are motorcycles with stylistic/structural changes to the mass-produced models offered by major manufacturers</p>
            </article>
            <article>
              <h1>Bobber</h1>
              <p>Bobbers are custom motorcycles stripped of unnecessary components</p>
            </article>
            <article>
              <h1>V-Twin</h1>
              <p>V-Twin engines are two-cylinder piston engines where the pistons are arranged in a V shape and share a common crankshaft</p>
            </article>
          </section>
        </section>
        <section class="showcase">
          <h1 class="title">My Top Cruisers</h1>
          <section class="images">
            <a href="motorcycle/2019-honda-shadow-phantom">
              <img src={"/images/sideview/2019-honda-shadow-phantom.jpg"} />
              <h1>2019 Honda Shadow Phantom</h1>
            </a>
            <a href="motorcycle/2019-kawasaki-vulcan-s">
              <img src={"/images/sideview/2019-kawasaki-vulcan-s.jpg"} />
              <h1>2019 Kawasaki Vulcan S</h1>
            </a>
            <a href="motorcycle/2019-yamaha-v-star-250">
              <img src={"/images/sideview/2019-yamaha-v-star-250.jpg"} />
              <h1>2019 Yamaha V-Star 250</h1>
            </a>
          </section>
        </section>
      </main>
    </body>
  )
}
