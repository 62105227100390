import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import HomePage from "./containers/Homepage";
import Motorcycle from "./containers/Motorcycle";
import Selection from "./containers/Selection";

export default function Routes(props) {
  return (
    <Switch>
      <Route exact path="/">
        <HomePage />
      </Route>
      <Route exact path="/motorcycle/:key">
        <Motorcycle motorcycles={props.motorcycles} />
      </Route>
      <Route exact path="/selection">
        <Selection motorcycles={props.motorcycles} />
      </Route>
    </Switch>
  );
}
